export const MINIMUM_AMOUNT = 1;
export const MINIMUM_FEE = 1000000;
export const TRANSACTIONS_TABLE_ITEMS_PER_PAGE = 10;
export const VALIDATORS_TABLE_ITEMS_PER_PAGE = 100;
export const DEFAULT_FEE = 0.1;
export const DEFAULT_AMOUNT = 0;
export const INITIAL_TRANSACTION_AMOUNT = 0;
export const MINIMUM_NONCE = 0;
export const DEFAULT_VALID_UNTIL_FIELD = "4294967295";
export const DELEGATION_FEE_THRESHOLD = 2000000000;
